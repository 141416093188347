<template>
    <div class="apartment-detail">
        <app-detail :opt="opt" ref="detail"></app-detail>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    title: "新增参数",
                    form: [{
                            label: "ID",
                            key: "id",
                            isCol50: true,
                            isWidth67: true,
                            disabled: true
                        },
                        {
                            label: "Key",
                            key: "configKey",
                            isCol50: true,
                            isWidth67: true,
                            rules: [{
                                required: true,
                                message: "请输入Key",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: "标题",
                            key: "configName",
                            rules: [{
                                required: true,
                                message: "请输入标题",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: "值",
                            key: "configValue",
                            rules: [{
                                required: true,
                                message: "请输入值",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: "参数类别",
                            key: "configType",
                            rules: [{
                                required: true,
                                message: "请输入参数类别",
                                trigger: "blur"
                            }]
                        },
                        {
                            label: "备注",
                            key: "remark",
                            type: "textarea"
                        },
                    ],
                    buttons: [{
                        name: "提交",
                        show: ["add", "edit"],
                        cb(form) {
                            console.log(form);
                            let dto = {
                                configKey: form.configKey,
                                configName: form.configName,
                                configValue: form.configValue,
                                configType: form.configType,
                                configState: form.configState ? 1 : 0,
                                remark: form.remark
                            };
                            let url = "landlord-service/sysConfig/add";
                            let message = "新增参数成功";
                            if (form.id) {
                                url = "landlord-service/sysConfig/update";
                                dto.id = form.id;
                                message = "编辑参数成功";
                            }
                            _this.post(url, dto).then(function () {
                                _this.$message({
                                    showClose: true,
                                    message: message,
                                    type: "success"
                                });
                                _this.$back();
                            });
                        }
                    }],
                    get(opt) {
                        _this.get('landlord-service/sysConfig/detail/'+ opt.id).then(res => {
                            res.parameterState = res.configState == 1 ? "启用" : "禁用";
                            res.configState = res.configState == 1 ? true : false;
                            opt.cb(res);
                        })
                    }
                }
            };
        },
        created: function () {
            console.log("parameter-detail created!!");
        },
        activated() {
            console.log('parameter-detail activated')
            let _this = this;
            let type = _this.$route.params.type;
            _this.$refs.detail.title = ["新增参数", "编辑参数", "查看参数"][{
                add: 0,
                edit: 1,
                show: 2
            } [type]];
            let idFlag=false;//判断form是否有id
            _this.opt.form.forEach((item,index) => {
                if (item.key == 'configState') {
                    _this.opt.form.splice(index, 1)
                }
                //新增无id
                if(item.key == 'id' && type == 'add') {
                    _this.opt.form.splice(index, 1);
                }
                else if(item.key == 'id' && (type == 'show' || type == 'edit')){
                      idFlag=true;
                } 
                // if(item.key == "id" && type == 'add') {
                //     item.notShow = true;
                // }else {
                //     item.notShow = false;
                // }
            })
            if((type == 'show' || type == 'edit') && idFlag==false){
                _this.opt.form.unshift({
                            label: "ID",
                            key: "id",
                            isCol50: true,
                            isWidth67: true,
                            disabled: true
                        })
            }
            if (type == 'show') {
                console.log(' _this.opt.form.length:' + _this.opt.form.length)
                _this.opt.form.forEach((item, index) => {
                    console.log('index: ' + index + '--' + 'item: ' + item.label)
                    if (item.key == 'configKey' && _this.opt.form[index + 1].key != 'parameterState') {
                        _this.opt.form.splice(index + 1, 0, {
                            label: "参数状态",
                            key: "parameterState",
                            isCol50: true,
                            isWidth67: true,
                        }, {
                            label: "创建时间",
                            key: "createTime",
                            isCol50: true,
                            isWidth67: true,
                        })
                    }
                })
            } else {
                _this.opt.form.forEach((item, index) => {
                    if (item.key == 'parameterState') {
                        _this.opt.form.splice(index, 2)
                    }
                })
                _this.opt.form.push({
                    label: "参数状态",
                    key: "configState",
                    type: "switch",
                    rules: [{
                        required: true,
                    }]
                })
            }
        },
        methods: {}
    };
</script>
<style lang="scss">
    .img-list {
        white-space: nowrap;
        overflow-x: auto;

        img {
            margin-right: 10px;
            height: 146px;
            width: 146px;
            cursor: pointer;
        }
    }
</style>